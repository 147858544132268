import staffShiftApi from "@/api/common/staffShiftApi";
import { mapState } from "vuex";
import moment from "moment";
import commonAct from "@/commonActionHandle.js";
import baseComponent from "@/scripts/baseComponent";
//moment().isoWeekday(8);
export default {
    extends: baseComponent,
    props: {
        isManagement: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            offsetWeek: 0,
            staffShiftData: [],
            staffScheduledData: [],
            dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
            moment: moment,
            intevalUpdate: null
        };
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.auth.user,
            rolesInfo: (state) => state.auth.roles,
            staffsInfo: (state) => state.staffs,
            lowBalanceUser: (state) => state.lowBalanceUser,
            $emailAllowedChangeSource: (state) => state.emailAllowedChangeSource,
            decentralization: (state) => state.auth.decentralization,
            currentCalendarIndex: (state) => parseInt(state.common.calendarIndex),
            currentDayOfWeek: (state) => parseInt(state.common.currentDayOfWeek),
        }),
        currentLocalTime() {
            return moment(moment().format('HH:mm:ss'), 'HH:mm:ss');
        },
        startOfWeek() {
            if (moment().isoWeekday() === 7) {
                return moment().startOf('week').isoWeekday(1).add((this.offsetWeek * 7), 'days');
            }
            else {
                return moment().startOf('week').isoWeekday(7).add(1, 'days').add((this.offsetWeek * 7), 'days');
            }
        }
    },
    mounted() {
        this.getStaffShift(this.offsetWeek);
        if (this.intevalUpdate) clearInterval(this.intevalUpdate);
        this.intevalUpdate = setInterval(() => {
            this.$forceUpdate();
        }, 10000);
    },
    destroyed() {
        if (this.intevalUpdate) clearInterval(this.intevalUpdate);
    },
    methods: {
        hasPerMissionChange() {            
            return (['yendph@irender.vn', 'thuongqth@irender.vn'].includes(this.userInfo.userEmail) || this.rolesInfo.includes('OWNED') || this.rolesInfo.includes('QA'));
        },
        isMatchNow(indexDOW, shiftItem) {
            return moment(this.startOfWeek).add(indexDOW, 'days').format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')
                    && (this.currentLocalTime.isBetween(shiftItem.startTimeObject, shiftItem.endTimeObject));
        },
        changeWeek(offset) {
            this.offsetWeek = this.offsetWeek + offset;
            this.getStaffShift(this.offsetWeek);
        },
        getStaffShift(offset) {
            this.showLoading();
            staffShiftApi.getStaffShiftData(offset).then((response) => {
                if (response.data.result === 0) {
                    this.getStaffScheduled();
                    this.staffShiftData = response.data.data.map(x => {
                        let isEndtimeZerpo = x.endTime === "00:00:00" || (x.endTime.hours * 3600 + x.endTime.minutes * 60 + x.endTime.seconds === 0);
                        let endtimeTemp = moment(`${x.endTime.hours}:${x.endTime.minutes}:${x.endTime.seconds}`, 'HH:mm:ss');
                        return {
                            ...x,
                            startTimeObject: moment(`${x.startTime.hours}:${x.startTime.minutes}:${x.startTime.seconds}`, 'HH:mm:ss'),
                            endTimeObject: isEndtimeZerpo ? endtimeTemp.add(1, 'days') : endtimeTemp
                        };
                    });
                    this.hideLoading();
                }
            }).catch(error => {
                console.error(error);
                this.hideLoading();
            });
        },
        getShiftFullEndtime(indexDOW, shiftItem) {
            let isEndtimeZerpo = shiftItem.endTime === "00:00:00" || (shiftItem.endTime.hours * 3600 + shiftItem.endTime.minutes * 60 + shiftItem.endTime.seconds === 0);
            let endtimeTemp = moment(`${moment(this.startOfWeek).add(indexDOW, 'days').format('DD-MM-YYYY')} ${shiftItem.endTimeString}`, 'DD-MM-YYYY HH:mm:ss');
            return isEndtimeZerpo ? endtimeTemp.add(1, 'days') : endtimeTemp;
        },
        getShiftFullStarttime(indexDOW, shiftItem) {
            return moment(`${moment(this.startOfWeek).add(indexDOW, 'days').format('DD-MM-YYYY')} ${shiftItem.startTimeString}`, 'DD-MM-YYYY HH:mm:ss');
        },
        getStaffScheduled() {
            staffShiftApi.getStaffScheduled({ weekOffset: this.offsetWeek }).then((response) => {
                if (response.data.result === 0) {
                    this.staffScheduledData = response.data.data.map(x => {
                        return {
                            ...x,
                            startTimeObject: moment.utc(x.startTime).add((this.offsetWeek * 7), 'days'),
                            endTimeObject: moment.utc(x.endTime).add((this.offsetWeek * 7), 'days')
                        };
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        getScheduledByShift(indexDOW, shiftItem, isCheckinMode = true) {
            let shiftStartTime = this.getShiftFullStarttime(indexDOW, shiftItem);
            let shiftEndTime = this.getShiftFullEndtime(indexDOW, shiftItem);
            let scheduledItem = this.staffScheduledData.find(x => {
                return moment(moment.utc(x.startTime)).local().isSame(shiftStartTime);
            });
            return scheduledItem;
        },
        setStaff(indexDOW, shiftItem, itemStaff) {
            let startTime = this.getShiftFullStarttime(indexDOW, shiftItem);
            //let endTime = this.getShiftFullStarttime(indexDOW, shiftItem);
            staffShiftApi.setStaff({ shiftId: shiftItem.id, staffId: itemStaff.id, checkInDate: startTime }).then((response) => {
                if (response.data.result === 0) {
                    this.getStaffShift(this.offsetWeek);
                    this.$forceUpdate();
                    this.$message({
                        dangerouslyUseHTMLString: true,
                        message: `Congrats, set staff <strong class='text-primary'>${itemStaff.fullName}</strong> on shiff <strong class='text-primary'>${shiftItem.name} ${startTime.format('DD-MM-YYYY')}</strong> succeed.`,
                        type: 'success'
                    });
                }
                this.hideLoading();
            }).catch(error => {
                console.error(error);
                this.hideLoading();
            });
        },
        checkIn(shiftItem) {
            commonAct.showConfirm(
                `<strong>${this.userInfo.fullName}</strong> confirm check-in on shift <strong>${shiftItem.name}</strong>. This operation cannot be undo. Continue.. ?`,
                () => {
                    this.showLoading();
                    staffShiftApi.checkIn({ shiftId: shiftItem.id }).then((response) => {
                        if (response.data.result === 0) {
                            this.getStaffShift(this.offsetWeek);
                            this.$forceUpdate();
                            this.$message({
                                message: 'Congrats, check-in succeed.',
                                type: 'success'
                            });
                        }
                        this.hideLoading();
                    }).catch(error => {
                        console.error(error);
                        this.hideLoading();
                    });
                }
            );
        }
    }
}